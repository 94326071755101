import React, { useContext } from "react";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";


const experiences = [
  {
    role: "Software Engineer/.Net Developer",
    company: "ValueLabs Solutions - M7 Group SA project",
    dates: "June 2020 - July 2023",
    responsibilities: [
      "Worked as a .Net Developer for a DTH provider client M7 Group SA, where I was responsible for developing and maintaining Web APIs, WCF services, MVC applications",
      "Developed abackend WebAPIusing .NET to streamline order processing for a CRM system, resulting in a 30% improvement in processing efficiency",
      "Led the migration of legacy backend services from VB6 to a modern .NET Web API as part of a CRM migration project, successfully transitioning the system from IBS to ICX with zero downtime and improving maintainability by 50%",
      "Created a.NET console application that interacts with MS SQL Server to identify and automatically reprocess failed orders, reducing manual intervention by 70% and decreasing service desk tickets related to order failures",
      "Led the development of a .NET MVCapplication for bulk importing test customer data from Excel files into the CRM via APIs, automating workflows and improving data entry efficiency across QA, marketing, and monitoring teams, saving 10 hours of manual work weekly",
      "Modernized several legacy ASP.NET and VB6 applications by migrating them to the .NET Framework, reducing technical debt by 40% and enabling faster feature deployments and seamless future scalability",
      "Collaborated with cross-functional teams to define project scope and deliverables",
      "Received \"Raising Star of the Month\" Award in July 2022",
    ],
  }
];

const Experience = () => {
  const theme = useContext(ThemeContext);

  return (
    <div
      className={
        theme.state.darkMode ? "pb-20 bg-fixed bg-gray-100" : "pb-20 bg-black"
      }
    >
      <div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 px-4 pt-20"
        id="experience"
      >
        <h2
          className={
            theme.state.darkMode
              ? "text-5xl font-bold px-4 md:px-0 text-center"
              : "text-5xl font-bold px-4 md:px-0 text-center text-white"
          }
        >
          Experience
        </h2>
        <div className="">
          <h4 className="mt-16 text-3xl font-semibold text-blue-500">
            My Work Journey
          </h4>
          <div className="mt-8 flex md:flex-col justify-between flex-col md:items-stretch items-center">
            {experiences.map((exp, index) => (
              <motion.div
                key={index}
                initial="hidden"
                whileInView={"visible"}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 },
                }}
                className={
                  theme.state.darkMode
                    ? "md:w-full p-6 bg-white rounded-lg flex flex-col mt-8"
                    : "md:w-full p-6 bg-gray-100 rounded-lg flex flex-col mt-8"
                }
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-bold">{exp.role}</h3>
                  <span className="text-black text-lg">
                    {exp.dates}
                  </span>
                </div>
                <div className="text-lg text-black">
                  <strong>{exp.company}</strong>
                  <ul className="list-disc list-inside mt-4">
                    {exp.responsibilities.map((res, idx) => (
                      <li key={idx} className="mb-2">
                        {res}
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
