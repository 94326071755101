import React, { useContext } from "react";
import { contactLinks } from "../constants";
import { ThemeContext } from "../themeProvider";

const Contact = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div
      id="contact"
      className={
        darkMode
          ? "bg-gray-100 pt-24 md:h-screen"
          : "bg-black pt-24 text-white md:h-screen"
      }
    >
      <div className="max-w-7xl mx-auto x-4 sm:px-6 lg:px-8 px-4 ">
        <h2 className="text-5xl font-bold px-4 md:px-0 text-center z-0">
          Contact
        </h2>
        <div>
          <h4 className="mt-12 text-3xl font-semibold text-blue-500">
            Connect with me
          </h4>
          <p className="text-gray-500 text-xl">
            If you want to know more about me or my work, or if you'd just like
            to say hello, feel free to connect through the following channels:
          </p>
        </div>

        {/* Contact Links Section */}
        <div className="w-full flex flex-col items-start mt-12 md:mt-6">
          <h1 className="text-3xl font-bold">Email</h1>
          <a
            href="mailto:sagarambati.dev@gmail.com"
            className="mb-12 mt-4 font-semibold text-blue-700 block uppercase"
          >
            sagarambati.dev@gmail.com
          </a>

          <h1 className="text-3xl font-bold">Social</h1>
          <ul className="flex">
            {contactLinks.map((el) => (
              <a
                key={el.name}
                href={el.link}
                className="mr-6 cursor-pointer mt-4 hover:scale-125 flex flex-col justify-center items-center"
              >
                <img alt={el.name} src={el.url} className="w-8 h-8" />
              </a>
            ))}
          </ul>
        </div>
      </div>

      <div
        className={
          darkMode
            ? "w-full bg-white text-black text-lg py-3 flex justify-center md:mt-20"
            : "w-full bg-gray-900 text-white text-lg py-3 flex justify-center md:mt-20"
        }
      >
        Made with
        <div className="text-red-500 px-2 text-2xl">&#10084;</div>
        by Sagar
      </div>
    </div>
  );
};

export default Contact;
