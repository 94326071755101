export const techStack = [
  {
    name: 'C#',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/csharp/csharp-original.svg',
  },
  {
    name: 'Python',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg',
  },
  {
    name: 'Javascript',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg',
  },
  {
    name: 'HTML',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg',
  },
  {
    name: 'CSS',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg',
  },
  {
    name: '.Net',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dot-net/dot-net-original.svg',
  },
  {
    name: '.Net Core',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dotnetcore/dotnetcore-original.svg',
  },
  {
    name: 'NodeJS',
    link: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg',
  },
  {
    name: 'React JS',
    link: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg',
  },
  {
    name: 'Tailwind CSS',
    link: 'https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg',
  },
  {
    name: 'MS SQL Server',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/microsoftsqlserver/microsoftsqlserver-original-wordmark.svg',
  },

  {
    name: 'MySQl',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg',
  },

  {
    name: 'Mongo DB',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg',
  },
  {
    name: 'AWS',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg',
  },
  {
    name: 'Docker',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg',
  },

  {
    name: 'Git',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg',
  },
  {
    name: 'Github',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
  },

  {
    name: 'VS Code',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg',
  },
  {
    name: 'Visual Studio',
    link: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/visualstudio/visualstudio-original.svg',
  },
  {
    name: 'Postman',
    link: 'https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg',
  },
];

export const contactLinks = [
  {
    name: 'Github',
    url: 'https://img.icons8.com/doodle/40/000000/github--v1.png',
    link: 'https://github.com/thevenuz',
  },
  {
    name: 'LinkedIn',
    url: 'https://img.icons8.com/doodle/40/000000/linkedin--v2.png',
    link: 'https://www.linkedin.com/in/sagar-ambati/',
  },
];

export const serviceData = [
  {
    name: 'Web Application Development',
    desc: 'Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design user interface design  authoring, including standardised code and proprietary software user experience design and search engine',
    img: 'https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-web-development-coding-kiranshastry-lineal-color-kiranshastry.png',
  },
  {
    name: 'Mobile Application Development',
    desc: `We provide a range of mobile application development services
    including custom mobile development on Android platforms, building
    cross-platform apps, designing user experience and integrating
    novel mobile interfaces such as chat and voice`,
    img: 'https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-app-development-responsive-web-design-justicon-lineal-color-justicon.png',
  },

  {
    name: 'Backend Development',
    desc: `Graphic design is a craft where professionals create visual
    content to communicate messages. By applying visual hierarchy and
    page layout techniques, designers use typography and pictures to
    meet users' specific needs and focus on the logic of displaying
    elements in interactive designs, to optimize the user experience.`,
    img: 'https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-backend-no-code-flaticons-flat-flat-icons.png',
  },
];

export const projectsData = [
  {
    name: 'Wattpad bot for Discord',
    shortDescription:
      'A discord bot that retrieves shares updates of new chapters and announcements from Wattpad Stories and Authors.',
    longDescription: `A discord bot that uses web scrpaing to scrape the wattpad website for new chapters of stories and announcements from authors, 
      and share these updates on discord servers.\n The bot is built using Python, and uses the BeautifulSoup library for web scraping, 
      and the hikari library for discord interactions.\n The bot reached over 800 servers and tracking 800+ stories and 500+ servers.`,
    techStack: ['Python', 'Webscraping', 'BeautifulSoup', 'hikari'],
    github: 'https://github.com/thevenuz/Discord-Wattpad-Bot',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/Discord-Wattpad-Bot',
      },
    ],
  },
  {
    name: 'NFL livescore bot for Discord',
    shortDescription:
      'A discord bot that fetches live scores of ongoing NFL games.',
    longDescription: `A discord bot that fetches live scores of ongoing NFL games and provides final scores of recent games.\n
      The bot is built using Python, and uses ESPN's API to get live scores of the games and the hikari library for discord interactions.\n The bot reached over 500 servers.`,
    techStack: ['Python'],
    github: 'https://github.com/thevenuz/NFL-Discord-Bot',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/NFL-Discord-Bot',
      },
    ],
  },
  {
    name: 'Arisu camp',
    shortDescription: 'A simple website for a fictional character.',
    longDescription: `A simple website for a fictional character built with the aim of bringing the community together.\n 
      The site has been built with using React JS and Tailwind CSS.\n The site has a simple design and is responsive optimized for mobile and larger screens and has both light mode and drak mode.`,
    techStack: ['React JS', 'Tailwind CSS'],
    github: 'https://github.com/thevenuz/arisu',
    livesite: 'https://www.arisusakayanagi.com/',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/arisu',
      },
      {
        name: 'live site',
        icon: 'internet.png',
        url: 'https://www.arisusakayanagi.com/',
      },
    ],
  },
  {
    name: 'xify',
    shortDescription:
      'A Chrome/Firefox extension to automatically rewrite Twitter/x.com links to vxtwitter/fxtwitter links, to resolve embed issues in Discord.',
    longDescription: `A Chrome/Firefox extension to automatically rewrite Twitter/x.com links to vxtwitter/fxtwitter links, to resolve embed issues in Discord.\n
      The extension aims to provide a seamless experience for users who share twitter links on discord.\n
      This extension was built using Javascript and is available on the Chrome and Firefox extension stores.\n`,
    techStack: ['Javascript'],
    github: 'https://github.com/thevenuz/xify',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/xify',
      },
      {
        name: 'Chrome store',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/chrome/chrome-original.svg',
        url: 'https://chromewebstore.google.com/detail/xify/cihieeigmpndggpojmhjndlgjdmlgaoe',
      },
      {
        name: 'Firefox store',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/firefox/firefox-original.svg',
        url: 'https://addons.mozilla.org/en-US/firefox/addon/xify/',
      },
    ],
  },
  {
    name: 'aniwrap.py',
    shortDescription:
      'An asynchronous Python wrapper for the My Anime List API.',
    longDescription: `An asynchronous Python wrapper for the My Anime List API.\n 
      This was built to enable developers to efficiently interact with the platform\'s data.\n 
      The library was developed using Python and aiohttp.\n 
      The library has over 30 downloads each month.`,
    techStack: ['Python'],
    github: 'https://github.com/thevenuz/aniwrap.py',
    pypiLink: 'https://pypi.org/project/aniwrap/',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/aniwrap.py',
      },
      {
        name: 'pypi',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/pypi/pypi-original.svg',
        url: 'https://pypi.org/project/aniwrap/',
      },
    ],
  },
  {
    name: 'Timezoner',
    shortDescription:
      'A chrome/firefox extension that automatically converts the times on websites to your local timeozone.',
    longDescription: `A chrome/firefox extension that automatically converts the times on websites to your local timeozone.\n
      The extension aims to take away the pain of doing mental math to convert times to your local timezone.\n
      This extension was built using Javascript and is available on the Chrome and Firefox extension stores.\n`,
    techStack: ['Python'],
    github: 'https://github.com/thevenuz/timezoner',
    externalLinks: [
      {
        name: 'github',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg',
        url: 'https://github.com/thevenuz/timezoner',
      },
      {
        name: 'Chrome store',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/chrome/chrome-original.svg',
        url: 'https://chromewebstore.google.com/detail/timezoner/kilojkiejkaaepjepjbkengfhdnlamne',
      },
      {
        name: 'Firefox store',
        icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/firefox/firefox-original.svg',
        url: 'https://addons.mozilla.org/en-US/firefox/addon/the-timezoner/',
      },
    ],
  },
];
