import React from "react";
import { motion } from "framer-motion";

const Card = (project) => {
  return (
    <motion.div
      initial={"hidden"}
      whileInView={"visible"}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="w-[450px] h-auto bg-white rounded-lg border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700 my-4 mx-auto hover:shadow-xl transition-shadow duration-300"
    >
      <div className="p-5 flex flex-col justify-between h-[300px]">
        <div>
          <a target="_blank" rel="noopener noreferrer">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {project.project.name}
            </h5>
          </a>

          {/* Short description with larger text */}
          <p className="mb-3 text-md text-gray-700 dark:text-gray-400 h-[70px] overflow-hidden text-ellipsis">
            {project.project.shortDescription}
          </p>
        </div>

        {/* Tech Stack with larger size and bold */}
        <p className="mb-3 text-lg font-extrabold text-gray-900 dark:text-gray-300 break-words">
          Tech Stack: {project.project.techStack.join(", ")}
        </p>

        {/* Display External Links with Icons */}
        <div className="flex space-x-4">
          {project.project.externalLinks.slice(0, 3).map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              {/* Display the icon as an image */}
              <img
                src={link.icon}
                alt={`${link.name} icon`}
                className="w-6 h-6 mr-2"
              />
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Card;
